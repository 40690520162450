import { INV_ACTION,SUB_ACTION, headers } from "../constants";
import api from "../api";
import { Occurences, getLocalStorage, setLocalStorage, showError } from "../../helpers/utils";
import { openNotificationWithIcon, openNotificationWithIconErr } from "./common";
import { getAccountsModel, getInvsModel, getUnInvsModel, getUserInvsModel } from "../../helpers/model/investmodel";
import moment from "moment";

export const invAction = value => ({
    type: INV_ACTION,
    payload: value,
});

export const subAction = value => ({
    type: SUB_ACTION,
    payload: value,
});


// fetch all available investment
export const getAllInvestments = (payload, cb) => {
 
    return dispatch => {
        dispatch(invAction({invLoad:true}))
        api.get(`PaymentProof/api/PaymentProof/GetProducts`, headers)
        .then(res => {

            setLocalStorage('halalProd',res.data[0]);
            setLocalStorage('fifProd',res.data[1]);
            
            let options = res.data.map(item=> (
                getInvsModel(item)
            ))
            dispatch(invAction({invLoad:false,allInv:options}))
        })
        .catch(error => {
            dispatch(invAction({invLoad:false, invMessage:showError(error)}))
        });
    };
};

// gett all users invesments
export const getAllUserInvestments = (payload, cb) => {

    api.get('https://lotusapi.lotuscapitallimited.com/PaymentProof/api/PaymentProof/GetProducts',headers)
    .then(res => {

        setLocalStorage('halalProd',res.data[0]);
        setLocalStorage('fifProd',res.data[1]);
    });
    
    return dispatch => {
        dispatch(invAction({invLoad:true, fetchLoad:true}))
        // api.get(`/CustomerInvestment/${payload.id}/foruser/?isMudarabah=false&pageSize=100`, headers)
        api.get(`/Reports/api/Reports/DashBoard?ContactNo=${payload}`, headers)
        .then(res => {
            let options = Occurences(res.data.data).map(item=> (
              
                getUserInvsModel(item)
            ))
            
            let mud = options.filter(item => item.code === 'MT-DEP')
            let invs = options.filter(item => item.code !== "MT-DEP")

            // dispatch(subAction(subs))
            dispatch(invAction({invLoad:false, fetchLoad:false, userInvs:invs, mudInvs:mud}))
            
        })
        .catch(error => {
            dispatch(invAction({invLoad:false,fetchLoad:false, invMessage:showError(error)}))
        });
    };
};

// get  single invesment info
export const getInvestment = (payload, cb) => {

    return dispatch => {
        dispatch(invAction({fetchLoad:true}))

        api.get(`Investment/api/Investment/GetInvestment?ParentID=${payload.id}&FundType=${payload.type}&subAccountCode=${payload.code}`)
        .then(res => {
            
            dispatch(invAction({fetchLoad:false,currInv:getAccountsModel(res.data.data)}))
        })
        .catch(error => {
            dispatch(invAction({fetchLoad:false, invMessage:showError(error)}))
        });
    };
};

export const getInvestmentTopUp = (payload, cb) => {

    var userId = getLocalStorage('userInfo');

    return dispatch => {
        dispatch(invAction({fetchLoad:true}))

        api.get(`Investment/api/Investment/GetInvestment?ParentID=${userId.contId}&FundType=${payload.id}&subAccountCode=${payload.subCodeode}`)
        .then(res => {
            
            dispatch(invAction({fetchLoad:false,currInv:getAccountsModel(res.data.data)}))
        })
        .catch(error => {
            dispatch(invAction({fetchLoad:false, invMessage:showError(error)}))
        });
    };
};

// investment action topup redeem, liquidate
export const InvestmentAction = (url,payload, cb) => {
    
   //return openNotificationWithIcon('Try again later or use the old portal', 'Maintenance Ongoing')

   //console.log(payload)

    return dispatch => {
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        api.post(url, payload, headers)
        .then(res => {

            openNotificationWithIcon(res.data.message, 'Investment')
            dispatch(invAction({actionLoad:false,actionMessage:''}))

            cb && cb(res?.data?.data)
            //console.log("succes below")
            //console.log(res.data)
            url.includes("TopUp") &&   localStorage.removeItem('payment')
        })
        .catch(error => {
            console.log("Error below")
            console.log(error)
            var message = showError(error)
            if (message === "PaymentRef already exists" && url.includes("TopUp")  ) {
                cb && cb()
            } else {
                setLocalStorage('payment',payload)
            }
            openNotificationWithIconErr(showError(error), 'investment')
            dispatch(invAction({actionLoad:false, actionMessage:showError(error)}))
        });
    };
};
// redemption action 
export const newRedemptionAction = (url,payload, cb) => {

    //return openNotificationWithIcon('Try again later or use the old portal', 'Maintenance Ongoing')

    return dispatch => {
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        api.post(url, payload, headers)
        .then(res => {
            openNotificationWithIcon(res.data.message, 'Redemption')
            dispatch(invAction({actionLoad:false,actionMessage:''}))
            cb && cb(res?.data?.data)
            url.includes("TopUp") &&   localStorage.removeItem('payment')
        })
        .catch(error => {
            var message = showError(error)
            console.log(error)
            if (message === "PaymentRef already exists" && url.includes("TopUp")  ) {
                cb && cb()
            } else {
                setLocalStorage('payment',payload)
            }
            setLocalStorage('redemptionResult','False')
            //changed to success message wrongly, fix later
            //openNotificationWithIcon(res.data.message, 'Redemption')
            //openNotificationWithIconErr(showError(error), 'Redemption')
            cb && cb(error?.data?.data)
            dispatch(invAction({actionLoad:false, actionMessage:showError(error)}))
        });
    };
};

// redemption action 
export const redemptionAction = (url,payload, cb) => {

    //return openNotificationWithIcon('Try again later or use the old portal', 'Maintenance Ongoing')

    return dispatch => {
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        api.post(url, payload, headers)
        .then(res => {
            openNotificationWithIcon(res.data.message, 'Redemption')
            dispatch(invAction({actionLoad:false,actionMessage:''}))
            cb && cb(res?.data?.data)
            url.includes("TopUp") &&   localStorage.removeItem('payment')
        })
        .catch(error => {
            var message = showError(error)
            if (message === "PaymentRef already exists" && url.includes("TopUp")  ) {
                cb && cb()
            } else {
                setLocalStorage('payment',payload)
            }
            openNotificationWithIconErr(showError(error), 'Redemption')
            dispatch(invAction({actionLoad:false, actionMessage:showError(error)}))
        });
    };
};

export const createInvestment = (url,payload, cb) => {

    return dispatch => {
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        api.post(url, payload, headers)
        .then(res => {
            openNotificationWithIcon(res.data.message, 'Investment')
            dispatch(invAction({actionLoad:false,actionMessage:''}))
            cb && cb()
            localStorage.removeItem('payment')
        })
        .catch(error => {
            var message = showError(error)
            if (message === "PaymentRef already exists" ) {
                cb && cb()
            } else {
                setLocalStorage('payment',payload)
            }
            openNotificationWithIconErr(showError(error), 'investment')
            dispatch(invAction({actionLoad:false, actionMessage:message}))
        });
    };
};

export const makePayment = (url,payload, cb) => {

    return dispatch => {
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        api.post(url, payload, headers)
        .then(res => {
            openNotificationWithIcon(res.data.message, 'Investment')
            dispatch(invAction({actionLoad:false,actionMessage:''}))
            cb && cb()
            localStorage.removeItem('payment')
            
        })
        .catch(error => {
            var message = showError(error)
            if (message === "PaymentRef already exists" ) {
                cb && cb()
            } else {
                setLocalStorage('payment',payload)
            }
            openNotificationWithIconErr(showError(error), 'investment')
            dispatch(invAction({actionLoad:false, actionMessage:message}))
        });
    };
};



// fetch transactions 
export const getTransactions = (url, cb) => {
    return dispatch => {
        dispatch(invAction({transLoad:true}))
        api.get(url, headers)
        .then(res => {
            cb && cb(res.data)
            dispatch(invAction({transLoad:false}))
           
        })
        .catch(error => {
            dispatch(invAction({transLoad:false}))
        });
    };
};



//  GIFTS
export const purchaseGift = (payload, cb) => {
    return async dispatch => {
        dispatch(invAction({actionLoad:true}))
        await api.post(`/gifts/Api/Gifts/AddNewGift`, payload, headers)
        // dispatch(invAction({actionLoad:false}))
        .then(res => {
            cb && cb(res.data)
            dispatch(invAction({actionLoad:false,actionMessage:''}))
        })
        
        .catch(error => {
            dispatch(invAction({actionLoad:false, actionMessage:showError(error)}))
        });
    };

};

export const claimGift = (url, cb) => {
    return async dispatch => {
        dispatch(invAction({actionLoad:true}))
        await api.get(url, headers)
        // dispatch(invAction({actionLoad:false}))
        .then(res => {
            cb && cb()
            dispatch(invAction({actionLoad:false,actionMessage:''}))
        })
        
        .catch(error => {
            console.error(error )
            dispatch(invAction({actionLoad:false, actionMessage:showError(error)}))
        });
    };

};

export const getGifts = (url,payload, type) => {
    // eslint-disable-next-line
    const currDate = moment()

    return dispatch => {
        dispatch(invAction({invLoad:true}))
        // api.get(`${url}/${prevDate}/${currentDate}?pageSize=1000`, headers)
        api.get(`${url}`, headers)
        .then(res => {
            let options = res.data.data.map((item)=> (
                {
                    id:item.serialNumber,
                    amount:item.giftAmount,
                    name:item.benefProductName,
                    invId:item.benefProductName,
                    // unit:item.investmentUnit
                }
            ))  

            dispatch(invAction({invLoad:false,[type]:options}))
        })
        .catch(error => {
            dispatch(invAction({invLoad:false, invMessage:showError(error)}))
        });
    };
};


// charts
export const getPriceHistory = (url,payload, type) => {
    // var noDays = getCurrentYearDays()
    return dispatch => {
        dispatch(invAction({invLoad:true}))
        // Reports/api/Reports/AllPriceHistory?FundType=${payload}
        
        api.get(`Reports/api/Reports/PriceChart?NoDays=20`, headers)
        .then(res => {
            dispatch(invAction({invLoad:false,prices:res.data.data}))
        })
        .catch(error => {
            dispatch(invAction({invLoad:false, invMessage:showError(error)}))
        });
    };
};
export const getPieChart = (payload) => {
    return dispatch => {
        dispatch(invAction({invLoad:true}))
        api.get(`Reports/api/Reports/DashBoardPieChart?ContactNo=${payload}`, headers)
        .then(res => {
            dispatch(invAction({invLoad:false,pie:res.data.data}))
        })
        .catch(error => {
            dispatch(invAction({invLoad:false, invMessage:showError(error)}))
        });
    };
};

// accounts & all user investments
export const getAccountsInvs = (payload, cb) => {
    return dispatch => {
        dispatch(invAction({invLoad:true,fetchLoad:true}))
        api.get(`Investment/api/GetAllMyInvestments?contId=${payload}`, headers)
        .then(res => {
            let options =res.data.map((item,id)=> (
                getAccountsModel({...item, id:id})
            ))
            let subs = options.filter(item => item.subCode)

            let allInvs = res.data.filter(inv => !inv.subAccountNo.includes("-") )?.map(item=> (
                getUserInvsModel(item)
            ))

            let unInvs = res.data.filter(inv => inv.uninvestedFund > 0 )?.map(item=> (
                getUnInvsModel(item)
            ))
            
            let mud = allInvs.filter(item => item.code === 'MT-DEP')
            let invs = allInvs.filter(item => item.code !== "MT-DEP")

            dispatch(invAction({invLoad:false,allSubs:subs,fetchLoad:false, unInv:unInvs, userInvs:invs, mudInvs:mud}))
            
        })
        .catch(error => {
            dispatch(invAction({invLoad:false,fetchLoad:false, invMessage:showError(error)}))
        });
    };
};


// account statement
export const getStatmentPDF = (url,name) => {
    return dispatch => {
        dispatch(invAction({fetchLod:true, [name]:[]}))
        api.post(url, headers)
        .then(res => {
            dispatch(invAction({fetchLod:false,[name]:res.data?.data?.[0]?.investmentHistory}))
            
        })
        .catch(error => {
            dispatch(invAction({fetchLod:false, invMessage:showError(error)}))
        });
    };
};
